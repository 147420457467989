@use "@web/birdz-angular/src/scss/variables" as *;

.leaflet-container a.leaflet-popup-close-button {
    width: 28px !important;
    height: 28px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 24px !important;
}

.map-tooltip {
    font-family: $rubik;
    font-size: 12px;

    &::before {
        // to keep space for the close button
        content: "";
        width: 15px;
        height: 15px;
        float: right;
    }

    &__title {
        &::first-letter {
            text-transform: uppercase;
        }

        line-height: 1.6;
        font-weight: 600;
    }

    &__spacer {
        display: block;
        height: 6px;
    }

    &__small {
        font-size: 10px;
        color: $grey;
        font-style: italic;
    }

    &__badge {
        background: $grey-light;
        border-radius: 3px;
        display: inline-block;
        margin-right: 7px;
        padding: 3px 5px;
        font-size: 11px;
    }

    &__alarm {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__loader {
        transform: scale(0.6);
        position: relative;
        width: 40px;
        height: 40px;
        display: block;
        opacity: 0.8;
        margin: 10px auto;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 32px;
            height: 32px;
            margin: 4px;
            border: 4px solid var(--primary-default, #999);
            border-radius: 50%;
            animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: var(--primary-default, #999) transparent transparent transparent;
        }

        div:nth-child(1) {
            animation-delay: -0.45s;
        }

        div:nth-child(2) {
            animation-delay: -0.3s;
        }

        div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    img {
        vertical-align: middle;
    }

    .circle {
        margin-left: 7px;
        width: 15px;
        height: 15px;
        display: inline-block;
        position: relative;
        bottom: 1px;
        border-radius: 16px;
        vertical-align: middle;
        background-color: var(--color, $grey-light);
        border: 1px solid #000000aa
    }

    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}