@use './variables' as *;

.highcharts-tooltip {
  $spacing: 4px;
  background-color: #ffffff;

  .tooltip-spacer {
    display: block;
    width: 100%;
  }

  .tooltip-title {
    font-weight: bold;
  }

  .tooltip-compared {
    color: $grey;
  }

  .tooltip-divider {
    $color: #aaa;
    color: $color;
    width: 100%;
    text-align: center;
    position: relative;
    margin: $spacing 0;

    &__label {
      background: white;
      padding: 0 8px;
      z-index: 2;
      position: relative;
    }

    &:before {
      content: "";
      width: 100%;
      background: $color;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
    }
  }

  .tooltip-subline {
    width: 100%;
    // font-style: italic;
    font-size: 11px;
    color: $grey;

    strong {
      font-style: normal;
    }
  }

  .tooltip-table {
    width: 100%;
    border-collapse: collapse;

    td {
      border: 5px solid transparent;
      vertical-align: middle;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  div.tooltip-row .tooltip-row__square {
    margin-right: 8px;
  }

  .tooltip-row {
    display: flex;
    align-items: center;
    padding: $spacing 0;
    font-size: 14px;
    line-height: 1.2;
    font-family: $rubik;

    &__square {
      flex-shrink: 0;
      flex-grow: 0;
      width: 15px;
      height: 15px;
      display: block;

      &--rounded {
        border-radius: 15px;
      }

    }


    &__label {
      margin-right: 3 * $spacing;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
    }

    &__figure {
      text-align: right;
      margin-left: auto;
      padding-left: 3 * $spacing;

      font-size: 14px;
    }

    &__extra {
      padding-left: $spacing;
      font-style: italic;
      font-size: 10px;
      text-align: right;
      color: $grey;
    }

    &__compared {
      text-align: right;
      padding-left: 3 * $spacing;
      color: $grey;
      font-size: 14px;
    }
  }

  &--tiny {
    .tooltip-row {
      line-height: 1;
      padding: 0;
      font-size: 12px;

      &:not(:last-child) {

        .tooltip-row__square,
        .tooltip-row__label,
        .tooltip-row__figure,
        .tooltip-row__extra {
          padding-bottom: 0px;
        }
      }
    }
  }
}