@use "./variables" as *;
@use "./typography" as *;
html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: #f5f5f5;
  min-width: 320px;
  @extend .birdz-body2;
}

strong {
  font-weight: bold;
}
a {
  cursor: pointer;
  color: #12afc6;
}

:focus {
  outline: none;
}
