// colors
$purple: #804180;
$green: #28a745;
$red: #e84a5f;
$orange: #ff9933;
$blue: #52a5ef;
$white: #fff;
$dark: #353535;
$grey: #949494;
$grey-light: #f0f0f0;

// styleguide colors
$blueZodiac: #3A4255;
$pastelGreen: #7ED17C;
$cantaloupe: #F7A677;
$cornflower: #95CDE8;
$frenchManicure: #FFE6DC;
$rainDrop: #E7EEE8;
$hintOfMint : #E0F4D3;

// sizes
$header-height: 61px;
$space: 15px;
$space-l: 25px;

// fonts
$rubik: "Rubik", sans-serif;
$opensans: $rubik; // @deprecated: shouldn't be used anymore in consuming projects
$sinclair: $rubik; // @deprecated: shouldn't be used anymore in consuming projects
$thesans: $rubik; // @deprecated: shouldn't be used anymore in consuming projects

// breakpoints
$break-phone: 576px - 0.2px; // phone = 0 - 576
$break-phablet: 768px - 0.2px; // phablet = 576 - 768
$break-tablet: 992px - 0.2px; // tablet = 768 - 992
$break-desktop: 1200px - 0.2px; // desktop = 992 - 1200