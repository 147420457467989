@use "./variables" as *;

.birdz-h1 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 300;
    font-size: 96px;
    letter-spacing: -1.5px;
    color: #000000de;
}

.birdz-h2 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 300;
    font-size: 60px;
    letter-spacing: -0.5px;
    color: #000000de;
}

.birdz-h3 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 400;
    font-size: 48px;
    letter-spacing: 0;
    color: #000000de;
}

.birdz-h4 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: #000000de;
}

.birdz-h5 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0;
    color: #000000de;
}

.birdz-h6 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.25px;
    color: #000000de;
}

.birdz-body1 {
    line-height: 1.2;
    font-family: $rubik;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #00000099;
}

.birdz-body2 {
    line-height: 1.15;
    font-family: $rubik;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #00000099;
}

.birdz-subtitle1 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #00000099;
}
.birdz-subtitle2 {
    line-height: 1;
    font-family: $rubik;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #00000099;
}
.birdz-button {
    line-height: 1;
    font-family: $rubik;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}
.birdz-caption {
    line-height: 1;
    font-family: $rubik;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #00000099;
}
.birdz-overline {
    line-height: 1;
    font-family: $rubik;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 2px;
    color: #00000099;
    text-transform: uppercase;
}
