.birdz {
    &-period-range-picker-panel {
        &--granularity-month {
            mat-month-view {
                opacity: 0; // hide month view when the granularity is month in the range date picker
            }
        }
    }

    &-text-center {
        text-align: center;
    }

    &-select-viewport,
    &-select-viewport.cdk-virtual-scroll-viewport {
        height: 210px;
        max-height: 210px;
        max-width: 344px;
        width: 100%;
        overflow-x: hidden;

        /**
        * Below, make the options not wrapped like it was before Angular 15.
        * It is needed for the "SelectWithVirtualScrollDirective"'s method adjustViewportHeight() to work properly too.
        **/
        .mat-mdc-option .mdc-list-item__primary-text {
            white-space: nowrap;
            overflow: hidden;
        }

        /**
        * Below, this is needed for the tooltip to be aligned properly
        **/
        .cdk-virtual-scroll-content-wrapper {
            max-width: calc(100% + 20px) !important;
        }

    }

    &-filter-tab-group {
        .mat-mdc-tab.mdc-tab {
            min-width: 115px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            height: 72px;
            user-select: none;
        }
    }

    &-select-with-truncated-options {

        // use like this <mat-panel panelClass="birdz-select-with-truncated-options">
        .mat-mdc-option .mdc-list-item__primary-text {
            white-space: nowrap;
            overflow: hidden;
        }
    }


    &-select-trigger-small-font .mat-mdc-select-min-line {
        font-size: 12px;
    }
}

.cdk-overlay-pane:has(.birdz-wide-select) {
    min-width: 344px;
}