.console-error {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: -30vw;
    bottom: 0;
    width: 60vw;
    border-radius: 60vw / 100vw;
    background:red;
    animation: boom 800ms forwards;
    pointer-events:none;
    user-select: none;
    animation-timing-function: cubic-bezier(0.38, 0.99, 0.51, 0.93);
  }
  
  @keyframes boom{
      0% {
          transform: scaleX(.2) translateX(20%);
          opacity: .2;
      }
      50% {
          transform: scaleX(.5) translateX(0);
          opacity: .5;
      }
  
      100% {
          transform: scaleX(0) translateX(20%);
          opacity: 0;
      }
  }
  