@use "@angular/material" as mat;
@use 'sass:map';

@mixin birdz-theme($theme) {

    // expose the theme colors of the consuming app
    $variants: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;
    $colors: primary, accent, warn;

    :root {
        @each $color in $colors {
            @each $variant in $variants {
                --#{$color}-#{$variant}: #{map-get(map-get($theme, $color), $variant)};
                --#{$color}-contrast-#{$variant}: #{map-get(map-get(map-get($theme, $color), contrast), $variant)};

                // add default
                @if ($variant ==500) {
                    --#{$color}-default: #{map-get(map-get($theme, $color), $variant)};
                    --#{$color}-contrast-default: #{map-get(map-get(map-get($theme, $color), contrast), $variant)};
                }
            }
        }
    }

    // set the material theme
    @include mat.all-component-themes($theme);
}

$birdz-typography-config: mat.m2-define-legacy-typography-config($font-family: "Rubik, sans-serif");


// common palettes & themes
$birdz-warn-palette: mat.m2-define-palette(mat.$m2-red-palette);
$birdz-primary-palette-operational: mat.m2-define-palette((50: #e9e0e9,
            100: #c9b3c9,
            200: #a580a5,
            300: #804d81,
            400: #652666,
            500: #4a004b,
            600: #430044,
            700: #3a003b,
            800: #320033,
            900: #220023,
            A100: #f85fff,
            A200: #f52cff,
            A400: #ed00f8,
            A700: #d500df,
            contrast: (50: #000000,
                100: #000000,
                200: #000000,
                300: #ffffff,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #ffffff,
                A700: #ffffff,
            ),
        ));

$birdz-primary-palette-blee: mat.m2-define-palette((50: #e0e9e8,
            100: #b3c9c5,
            200: #80a59f,
            300: #4d8179,
            400: #26665c,
            500: #004b3f,
            600: #004439,
            700: #003b31,
            800: #003329,
            900: #00231b,
            A100: #5fffcc,
            A200: #2cffbc,
            A400: #00f8a9,
            A700: #00df98,
            contrast: (50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #ffffff,
            ),
        ));

$birdz-primary-palette-water: mat.m2-define-palette((50: #e2eaf0,
            100: #b7c9d9,
            200: #87a6c0,
            300: #5782a7,
            400: #336794,
            500: #0f4c81,
            600: #0d4579,
            700: #0b3c6e,
            800: #083364,
            900: #042451,
            A100: #c2ffff,
            A200: #c2ffff,
            A400: #8fd9e4,
            A700: #5da7b2,
            contrast: (50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #ffffff,
                500: #ffffff,
                600: #ffffff,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #ffffff,
                A700: #ffffff,
            ),
        ));
$birdz-accent-palette-water: mat.m2-define-palette((50: #e5f8fb,
            100: #bfedf4,
            200: #95e2ed,
            300: #6ad6e6,
            400: #4acde0,
            500: #2ac4db,
            600: #25bed7,
            700: #1fb6d2,
            800: #19afcd,
            900: #0fa2c4,
            A100: #f1fcff,
            A200: #bef2ff,
            A400: #8be7ff,
            A700: #72e2ff,
            contrast: (50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #000000,
                500: #000000,
                600: #000000,
                700: #000000,
                800: #000000,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
            ),
        ));
$birdz-accent-palette-operational: mat.m2-define-palette((50: #fceaf7,
            100: #f9ccec,
            200: #f5aadf,
            300: #f087d2,
            400: #ed6ec8,
            500: #ea54be,
            600: #e74db8,
            700: #e443af,
            800: #e13aa7,
            900: #db2999,
            A100: #ffffff,
            A200: #ffe6f5,
            A400: #ffb3e1,
            A700: #ff9ad8,
            contrast: (50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #000000,
                500: #000000,
                600: #000000,
                700: #ffffff,
                800: #ffffff,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
            ),
        ));
$birdz-accent-palette-blee: mat.m2-define-palette((50: #f2f7e8,
            100: #deecc5,
            200: #c8df9f,
            300: #b1d278,
            400: #a1c95b,
            500: #90bf3e,
            600: #88b938,
            700: #7db130,
            800: #73a928,
            900: #619b1b,
            A100: #eaffd3,
            A200: #d1ffa0,
            A400: #b8ff6d,
            A700: #acff53,
            contrast: (50: #000000,
                100: #000000,
                200: #000000,
                300: #000000,
                400: #000000,
                500: #000000,
                600: #000000,
                700: #000000,
                800: #000000,
                900: #ffffff,
                A100: #000000,
                A200: #000000,
                A400: #000000,
                A700: #000000,
            ),
        ));
// theme water
$birdz-theme-light-water: mat.m2-define-light-theme((color: (primary: $birdz-primary-palette-water,
                accent: $birdz-primary-palette-water,
                warn: $birdz-warn-palette ),
            density: 0,
            typography: $birdz-typography-config ));
$birdz-theme-dark-water: mat.m2-define-dark-theme((color: (primary: $birdz-primary-palette-water,
                accent: $birdz-primary-palette-water,
                warn: $birdz-warn-palette ),
            density: 0,
            typography: $birdz-typography-config ));

// theme operational
$birdz-theme-light-operational: mat.m2-define-light-theme((color: (primary: $birdz-primary-palette-operational,
                accent: $birdz-primary-palette-operational,
                warn: $birdz-warn-palette ),
            density: 0,
            typography: $birdz-typography-config ));
$birdz-theme-dark-operational: mat.m2-define-dark-theme((color: (primary: $birdz-primary-palette-operational,
                accent: $birdz-primary-palette-operational,
                warn: $birdz-warn-palette ),
            density: 0,
            typography: $birdz-typography-config ));

// theme blee
$birdz-theme-light-blee: mat.m2-define-light-theme((color: (primary: $birdz-primary-palette-blee,
                accent: $birdz-primary-palette-blee,
                warn: $birdz-warn-palette ),
            density: 0,
            typography: $birdz-typography-config ));
$birdz-theme-dark-blee: mat.m2-define-dark-theme((color: (primary: $birdz-primary-palette-blee,
                accent: $birdz-primary-palette-blee,
                warn: $birdz-warn-palette ),
            density: 0,
            typography: $birdz-typography-config ));

// material overrides ----------------------

// remove padding on autocomplete
div.mdc-menu-surface.mat-mdc-select-panel {
    padding-top: 0;
    padding-bottom: 0;
}

// admin theme
$birdz-admin-theme: $birdz-theme-light-operational;