birdz-table-wrapper,
.birdz-table-wrapper {
  width: 100%;
  position: relative;
  min-height: 500px;
  table {
    tr.mat-mdc-header-row{
      background-color: var(--primary-200, #86a6bf);
    }
    .mat-sort-header-arrow {
      color: white;
    }
    width: 100%;
    th,
    th.mat-mdc-header-cell {
      white-space: nowrap;
      vertical-align: middle;
      background-color: var(--primary-200, #86a6bf);
      color: white;
      font-weight: 400;
      font-size: 12px;
    }
    td.mat-mdc-cell {
      vertical-align: middle;
      color: #00000099;
      border-bottom-width: 0px;
      font-weight: 500;
      font-size: 14px;
    }
    .mat-mdc-row:nth-child(odd) {
      background: #f5f5f5;
    }
  }
}
