@use "./variables" as *;

.mat-mdc-snack-bar-container {
  position: relative;

  span {
    color: white;
  }

  .mdc-snackbar__surface {
    background-image: linear-gradient(45deg, #323232, #686868);
    --mdc-snackbar-container-color: #333;
    --mat-mdc-snack-bar-button-color: white;
  }

  &.success .mdc-snackbar__surface {
    background-image: linear-gradient(45deg, #2d9445, #86c21e);
    --mdc-snackbar-container-color: #{$green};
    --mat-mdc-snack-bar-button-color: white;
  }

  &.info .mdc-snackbar__surface {
    background-image: linear-gradient(45deg, #2a7ac2, $blue);
    --mdc-snackbar-container-color: var(--birdz-primary, #{$blue});
    --mat-mdc-snack-bar-button-color: white;

  }

  &.error .mdc-snackbar__surface {
    background-image: linear-gradient(45deg, #e84a5f, #f58669);
    --mdc-snackbar-container-color: #{$red};
    --mat-mdc-snack-bar-button-color: white;
  }

  &.warning .mdc-snackbar__surface {
    background-image: linear-gradient(45deg, #ff9933, #ffc74f);
    --mdc-snackbar-container-color: #{$orange};
    --mat-mdc-snack-bar-button-color: white;
  }
}